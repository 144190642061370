<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <disorder-template
      :heading="$t('orthorexia')"
      :next="next"
      :previous="previous"
      :sources="sources"
    >
      <p v-for="paragraph in $t('disorders-orthorexia')" :key="paragraph">
        {{ paragraph }}
      </p>
    </disorder-template>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DisorderTemplate from "@/components/DisorderTemplate.vue";

export default {
  name: "orthorexia",
  components: {
    DisorderTemplate,
    Breadcrumbs
  },
  data: () => ({
    next: {
      slug: "biggerexia",
      url: "/disorders/biggerexia"
    },
    previous: {
      slug: "adipositas",
      url: "/disorders/adipositas"
    },
    sources: [
      "[1] Ärzteblatt DÄG Redaktion Deutsches. Orthorexie: Sinnvoll oder pathologisch? Deutsches Ärzteblatt. Published January 10, 2014. Accessed July 9, 2021. https://www.aerzteblatt.de/archiv/152833/Orthorexie-Sinnvoll-oder-pathologisch"
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("disorders"),
          disabled: false,
          href: "/disorders"
        },
        {
          text: this.$t("orthorexia"),
          disabled: true
        }
      ];
    }
  }
};
</script>
